
#psocetak {
    background-color: #448cd4 !important;
}

.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;

    background-image: url("../images/komora2.jpg");
    height: 600px;
    width: 600px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    z-index: -10;
    position: relative;
}

.home-text {
    padding: 48px;
    text-align: left;
}

.home-text .title {
    text-align: left;
}

.home-text span:first-child {
    colsor: #283240;
}

.home-text span:nth-child(2) {
    colsor: #dd7d10;
}

.title{
    font-family: "Poppins", sans-serif;
    padding-left: 0;
    text-align: center;
    color: white;
    font-size: 38px;

    font-weight: 700;
    line-height: 42px;
}

.subtitle {
    color: white;
    font-family: "Poppins", sans-serif;
    margin-top: 27px;
    font-weight: 400;
    line-height: 24px;
    font-size: 14px;
}

















/*Slideshow*/
.img-nav-arrow {
    cursor: pointer;
    font-size: 24px;
    color: #448cd4;
    background-color: white;
    border-radius: 100%;
    padding: 10px;
}

.prev {
    position: absolute;
    left: 0;
    top: 50%;
}

.next {
    position: absolute;
    top: 50%;
    right: 0;
}
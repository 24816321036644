@font-face {
    font-family: "Sora-bold";
    src: url("./Sora/static/Sora-Bold.ttf");
}

@font-face {
    font-family: "Sora-regular";
    src: url("./Sora/static/Sora-Regular.ttf");
}

html {
    scroll-behavior: smooth;
  }

body{
    margin: 0;
    padding: 0;
    font-family: "Sora-regular",Arial,sans-serif;
}

h3{
    font-size: 1.3rem;
}

section {
    z-index: 0;
    padding: 100px 0 !important
}
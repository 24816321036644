.why-choose .choose-right {
    height: 100%;
    width: 100%;
    background-image: url(../images/komora2.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

@media only screen and (max-width: 450px) {
    .why-choose .choose-right {
        height: 350px !important;
        margin-top: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .why-choose .choose-right {
        height: 350px !important;
        margin-top: 30px;
    }
}

.Overview {
    padding: 100px 0 !important;
}

.section-title {
    text-align: center;
    margin-bottom: 60px;
    padding: 0 250px;
}

.section-title h2 {
    font-size: 30px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 24px;
    position: relative;
    color: #2C2D3F;
}

@media only screen and (max-width: 450px) {
    .section-title {
        margin-bottom: 30px;
        padding: 0 20px;
    }

    .Overview .single-feature::before {
        display:none;
    }

    .sisngle-features {
        text-align: center;
        position: relative;
        padding:0px;
        margin: 15px 0;
    }

    .singles-features h3 {
        padding-top: 105px;
        font-size: 20px;
    }
    
}

.section-title p {
    color: #888;
    font-size: 15px;
    margin-top: 15px;
    width: auto;
}

.single-feature p {
    color: #757575;
    marsgin: 0;
    line-height: 24px;
    font-weight: 400;
}

.Overview .single-feature {
    text-align: center;
    position: relative;
    padding: 10px 20px;
}

.Overview .single-feature::before {
    position: absolute;
    content: "";
    right: -72px;
    top: 60px;
    width: 118px;
    border-bottom: 3px dotted #1a76d1;
}

.Overview .single-feature.last::before{
	display:none;
}

.single-icon .icon {
    font-size: 40px;
    color: #1a76d1;
    position: absolute;
    left: 50%;
    margin-left: -50px;
    top: 0;
    height: 100px;
    width: 100px;
    line-height: 100px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 100%;
    transition: all 0.4s ease;
}

.Overview .single-feature:hover .signle-icon i{
	background:#1A76D1;
	color:#fff;
	border-color:transparent;
}

.Overview .single-feature h3{
	padding-top: 128px;
	color:#2C2D3F;
	font-weight:600;
	font-size:21px;
}

.Overview .single-feature p {
	margin-top: 20px;
}
.navbar {
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  display: none;
  width: fit-content;;
  width: 100%;
}

.navbar-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;
  position: relative;

  width: 100%;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.navbar-menu.open {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;

  max-height: 300px;
  opacity: 1;
}

.navbar {
  margin-top: 7px;
}

.navbar-menu li a {
  color: #fff;
  text-decoration: none;
  color: #2C2D3F;
  width: 100%;
}

.navbar-menu li a:hover {
  color: #1a76d1;
}

.navbar-menu li:first-child a {
  padding-top: 30px;
}

.navbar-toggle {
  cursor: pointer;
  display: none;
  float: right;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #1a76d1;
  margin: 5px 0;
  position: relative;
  transition: transform 0.5s ease, opacity 0.5s ease, top 0.5s ease;
}





.cross {
  position: absolute;
  right: 0;
}

.cross .bar:nth-child(1) {
  bottom: 0;
  transform: rotate(45deg);
  top: 8px;
}

.cross .bar:nth-child(2) {
  opacity: 0;
}

.cross .bar:nth-child(3) {
  transform: rotate(-45deg);
  bottom: 8px;
}





@media screen and (max-width: 768px) {

  .navbar-toggle, .navbar {
    display: block;
  }

  nav {
    display: none;
  }

  .mobisle-nav {
    width: 100%;
    display: flex;
    justify-content: end;
  }



}
.call-action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 20px 0;

    background-image: url('../images/komora2.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.call-action .overlay {
    background-position: -25px -50.6625px;
}

.call-action .content {
    text-align: center;
    padsding: 100px 265px;
    position: relative;
}

.overlay:before {
    content: '';
    position: absolute;
    top: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #1a76d1;
    opacity: 0.8;
    z-index: 0;
}

.call-action .content h2 {
    color: white;
    font-size: 32px;
    font-weight: 600;
    line-height: 46px;
}

.call-action .content p {
    color: white;
    margin: 30px 0;
    font-size: 15px;
}

.call-action .content .btn {
    background-color: white;
    margin-right: 20px;
    font-weight: 500;
    border: 1px solid white;
    color: #1a76d1;
}

.btn {
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    color: white;
    background-color: #1a76d1;
    border: 1px solid #1a76d1;
    transition: all 0.3s;
}

@media only screen and (max-width: 450px) {

}

@media only screen and (max-width: 767px) {
    .call-action .content {
        padding: 70px 0;
    }
}
#scrollUp {
    bottom: 15px;
    right: 15px;
    background-color: #1a76d1;
    color: white;
    font-size: 25px;
    width: 42px;
    height: 42px;
    text-align: center;
    line-height: 45px;
    padding: 0;
    border-radius: 50%;
    box-shadow: 0 0 10px #00000026;
    transition: opacity 0.4s;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* ScrollToTop.css */
.scroll-to-top {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    z-index: 2147483647;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    padding: 10px 20px;
    color: #fff;
    border: none;
    border-radius: 5px;
    transition: opacity 0.5s ease, visibility 0.5s;
}

.scroll-to-top.show {
    opacity: 1;
    visibility: visible;
}


/* Adjust size for smaller screens */
@media screen and (max-width: 768px) {
    .scroll-to-top {
        bottom: 10px;
        right: 10px;
        padding: 8px 16px;
    }
}

/* Adjust size for even smaller screens */
@media screen and (max-width: 480px) {
    .scroll-to-top {
        bottom: 5px;
        right: 5px;
        padding: 6px 12px;
    }
}

 .owl-stage {
    display: flex;
 }

 .owl-item {
    width: 200px;
    height: 200px;
    overflosw: hidden;
 }

 .owl-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
 }
.why-choose {
    background-color: white;
}

.why-choose .choose-left h3 {
    font-size: 24px;
    font-weight: 600;
    color: #2c2d3f;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 24px;
}

.why-choose .choose-left ul li {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    color: #868686;
}

.choose-left p {
    font-size: 14px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    color: #868686;
    margin-bottom: 35px;
}

.why-choose .choose-left h3:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    bottom: 0;
    width: 50px;
    height: 2px;
    background-color: #1a76d1;
    /*background-color: #f9a826;*/
}

.why-choose .choose-left li i {
    height: 15px;
    width: 15px;
    line-height: 15px;
    text-align: center;
    color: #1a76d1;
    font-size: 14px;
    border-radius: 100%;
    padding-left: 2px;
    margin-right: 16px;
}

.why-choose .choose-left li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.why-choose .choose-right-o2 {
    height: 100%;
    width: 100%;
    background-image: url(../images/O2.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

@media only screen and (max-width: 450px) {
    .why-choose .choose-right-o2 {
        height: 350px !important;
        margin-top: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .why-choose .choose-right-o2 {
        height: 350px !important;
        margin-top: 30px;
    }
}
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

header nav li{
  margin-right: 15px;
  float: left;
  position: relative;
  font-family: 'Poppins', sans-serif;
}

header nav li a {
  color: #2C2D3F;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 25px 12px;
  display: inline-block;
  position: relative;
}

.header .nav li:hover a {
  color: #1a76d1;
}

header nav li a:hover::before {
  opacity: 1;
  visibility: visible;
  width: 100%;
  transition:  0.4s ease
}

header nav li a::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background: #1a76d1;
  border-radius: 5px 5px 0 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
}

a {
  transition: all 0.3 ease;
  outline: none;
  box-shadow: none;
  text-decoration: none !important;
}


.contacst-button {
  margin-top: 12px;
}

.slicknav_menu {
  display: none;
}

.slicknav_menu {
  font-size: 16px;
  background: #4c4c4c;
  padding: 5px;
}

.slicknav_menu a {
  box-sizing: border-box;
}
@font-face {
    font-family: "Sora-bold";
    src: url("./Sora/static/Sora-Bold.ttf");
}

@font-face {
    font-family: "Sora-regular";
    src: url("./Sora/static/Sora-Regular.ttf");
}

.footer-body{
    /*border-top: solid 1px rgb(255, 0, 0);*/
    position: relative;
    display: flex;
    width: 100%;;
    bottom: 0%;
    font-family: "Sora-regular",Arial,sans-serif;
    font-size: 10px;
    padding: 5px 0;
    box-shadow: 0 -1px #888888;
    z-index: 1;
}

.copyright{
    margin: 0 auto;
}

.footer .copyright {
    padding: 25px 0;
    font-size: 12px;
    border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .single-footer h5 {
    color: white;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    padding-bottom: 20px;
    text-transform: capitalize;
    position: relative;
}

.footer .single-footer h5::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0px;
    height: 3px;
    width: 50px;
    background: white;
}

.quick-link:hover {
    color: #1a76d1;
    transform: translateX(5px);
    transition: all 0.4s;
}

.footer-text {
    text-decoration: none !important;
    color: white;
    display: block;
    font-size: 14px;
    transition: all 0.2s;
    width: fit-content;
    padding-bottom: 5px;
}

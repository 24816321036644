@font-face {
    font-family: "Sora-bold";
    src: url("./Sora/static/Sora-Bold.ttf");
}

@font-face {
    font-family: "Sora-regular";
    src: url("./Sora/static/Sora-Regular.ttf");
}

header {
    background-color: white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
}

.logo {
    float: left;
}

.logo h2 {
    margin: 0;
    padding: 10px 0;
    font-size: 24px;

    font-family: "Sora-bold",Arial,sans-serif;
    text-align: center;
    color: #284877;
}